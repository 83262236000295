<template>
  <v-app>
    <div class="empty-layout">
      <Header />
      <router-view />
      <Footer/>
    </div>  
  </v-app>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
export default {
  components: { 
    Header,
    Footer 
  },
  name: "authLayout"
}
</script>

<style scoped land="scss">
  .empty-layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
</style>